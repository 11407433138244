<script lang="ts">
	import OBSWebSocket from 'obs-websocket-js';
	import { Socket, io } from "socket.io-client";
	import { onDestroy, onMount } from 'svelte';

	let currentScene = '';
	let obsStatus = '';
	let socketioStatus = '';
	let socket: Socket;
	const obs = new OBSWebSocket();

	const sceneChanged = (event: {sceneName: string})=>{
		if(socket) {
			socket.emit('sceneChanged', {newScene: event.sceneName, oldScene: currentScene});
			currentScene = event.sceneName;			
		}
	}

	obs.on('CurrentProgramSceneChanged', sceneChanged);

	onMount(()=>{
		socket = io({
			auth: {
				obsControl: true
			}
		});
		socketioStatus = 'Connecting...';

		socket.on('connect', ()=>{
			socketioStatus = 'Connected';
		});

		socket.once('obsPassword', (password)=>{
			obsStatus = 'Connecting...';

			obs.connect('ws://127.0.0.1:4455', password)
				.then(info=>{
					obsStatus = 'Connected to obs ' + info.obsWebSocketVersion;
					return obs.call('GetCurrentProgramScene');
				})
				.then(res=>{
					sceneChanged({sceneName: res.currentProgramSceneName});
				})
				.catch((err)=>{
					obsStatus = 'Connection error';
					console.log(err);
				});
		});

		socket.on('changeScene', (newScene)=>{
			obs.call('SetCurrentProgramScene', {sceneName: newScene}).catch();
		});

		socket.on('connect_error', error=>{
			console.error('socketio error', error);
			socketioStatus = 'Error (see console)';
		});
	});

	onDestroy(()=>{
		socket.close();
		obs.disconnect().catch(e=>console.error('Error disconnecting from obs', e));
	});

</script>

<section>
	<h3>OBS link</h3>
	<p>Server: {socketioStatus}</p>
	<p>OBS: {obsStatus}</p>
	<p>Current scene: {currentScene}</p>
</section>

<style>

</style>