<script lang="ts">
	import { Router, Route } from "svelte-navigator";
    import NavMenu from "./NavMenu.svelte";
    import PrivateRoute from "./auth/PrivateRoute.svelte";
    import Console from "./routes/Console.svelte";
	import Home from "./routes/Home.svelte";
    import OBS from "./routes/OBS.svelte";
    import Twitch from "./routes/Twitch.svelte";
    import TwitchVerify from "./routes/Twitch/TwitchVerify.svelte";
	import Verify from "./auth/Verify.svelte";
    import ViewsList from "./routes/ViewsList.svelte";
    import View from "./routes/View.svelte";
    import Regie from "./routes/Regie.svelte";
</script>

<main>
	<Router>
		<NavMenu/>
		<section>
			<Route><Home/></Route>
			<Route path="/verify"><Verify/></Route>
			<Route path="/view" primary={false}><View/></Route>
			<PrivateRoute path="/console"><Console/></PrivateRoute>
			<PrivateRoute path="/twitch/verify"><TwitchVerify/></PrivateRoute>
			<PrivateRoute path="/twitch"><Twitch/></PrivateRoute>
			<PrivateRoute path="/regie"><Regie/></PrivateRoute>
			<PrivateRoute path="/views"><ViewsList/></PrivateRoute>
			<PrivateRoute path="/obs"><OBS/></PrivateRoute>
		</section>
	</Router>
</main>

<style>
	main { display: flex; flex-direction: row; width: 100%; min-height: 100vh; }
	section { flex: 1; margin: 2em; }
</style>
