
<script lang="ts">
    import { createEventDispatcher } from "svelte";
	//import { SchemaForm } from "@restspace/svelte-schema-form";
	import { SchemaForm } from "@detachhead/svelte-schema-form";

	export let service:string;
	export let action:string;
	export let params = {};

	const dispatch = createEventDispatcher();

	export let services;
	let serv;
	$: serv = service ? services.find(s=>s.name===service) : null;

</script>

<div class="container">
	<label>
		<select bind:value={service} on:change={()=>{action='';params={};dispatch('change');} }>
			<option value="">Choose service</option>
			{#each services.filter(s=>s.ready && s.actions) as s }
				<option value={s.name}>{s.name}</option>
			{/each}
		</select>
	</label>
	{#if service}
		<label>
			<select bind:value={action} on:change={()=>{params={};dispatch('change');} }>
				<option value="">Choose action</option>
				{#each (serv && serv.actions ? Object.entries(serv.actions) : []) as [a, def] }
					<option value={a}>{(def['title'] ?? a)}</option>
				{/each}
			</select>
		</label>
	{/if}
</div>
	{#if action }
		{#key action}
			{#if serv.actions[action].type === 'void' }
				No params for this action
			{:else}
				<SchemaForm schema={ serv.actions[action] } bind:value={params} />
			{/if}
		{/key}
	{/if}

<style>
	.container { display: flex; }
</style>