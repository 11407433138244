
<script lang="ts">
	import { Link, useLocation, useNavigate } from "svelte-navigator";
	import { user } from "./utils/stores";
	import { api } from "./utils/services";

	const location = useLocation();
	const navigate = useNavigate();

	async function disconnect() {
		await api('POST', '/api/auth/disconnect');
		navigate('/');
		user.set({
			connected: false,
		});
	}

</script>

{#if $user.connected && $location.pathname !== '/view' && $location.pathname !== '/regie' }
	<nav>
		<h1>Vartux</h1>
		<ul>
			<li><Link to="/">Home</Link></li>
			<li><Link to="/console">Console</Link></li>
			<li><Link to="/twitch">Twitch</Link></li>
			<li><Link to="/views">Views</Link></li>
			<li><Link to="/regie">Minirégie</Link></li>
			<li><Link to="/obs">OBS</Link></li>
		</ul>
		<button on:click={disconnect}>Disconnect</button>
	</nav>
{/if}

<style>
	nav { width: 16em; flex: 0 0; border-right: 1px solid #666; background: #1a1a1a; }
	h1 { text-align: center; font-size: 1.5em; color: #fff; padding: 1em; }
	ul { list-style: none; padding: 1em; }
	li { font-size: 1.25em; margin: .25em; color: #fff; }
	button { font-size: 1.25em; padding: .25em .5em; margin: 0 1em; }
</style>