
<script lang="ts">
	import ActionSelect from "./Console/ActionSelect.svelte";
    import LiveEvents from "./Console/LiveEvents.svelte";
	import { api, serviceCall } from "../utils/services";
    import { onMount } from "svelte";

	let serv: string;
	let ac: string;
	let url: string;
	let params = {};
	let p = new Promise(()=>{});
	let clearResult = ()=>{p = new Promise(()=>{})};

	$: url = (serv&&ac) ? '/api/services/'+serv+'/'+ac : '';

	let services = [];

	onMount(()=>{
		api('GET', '/api/services')
			.then(res=>services = res)
			.catch(e=>console.error('Error loading services', e));
	});
</script>

<section>
	<h3>Console (Mainly experimental)</h3>
	<div>
		<h4>Services</h4>
		<p> { services.filter(s=>s.ready).length } / { services.length } ready</p>
		<ul class="serviceList">
			{#if services.some(s=>!s.ready) }
				<p>failing services: </p>
				{#each services.filter(s=>!s.ready) as s}
					<li class={s.ready ? 'ready' : ''} >{s.name}</li>
				{/each}
			{/if}
		</ul>
	</div>
	<div>
		<h4>Run an action manually</h4>
		<ActionSelect {services} bind:service={serv} bind:action={ac} bind:params on:change={clearResult} />
		{#if serv && ac}
			<div class="action">
				<pre>{url}</pre>
			</div>
			<pre>{JSON.stringify(params, null, 2)}</pre>
			<button on:click={()=>{p = serviceCall(serv, ac, params)}}>Run</button>
			{#await p then value}
				<p>Result:</p>
				<pre>{JSON.stringify(value, null, 2)}</pre>
			{:catch e}
				<p>Error:</p>
				<p>{e.message}</p>
			{/await}
		{/if}
	</div>
	<div class="events">
		<h4>Real time events</h4>
		<LiveEvents/>
	</div>
</section>

<style>
	.serviceList { display: flex; width: 100%; flex-flow: row wrap; }
	.serviceList li { display: block; padding: 0 .5em; color: red; }
	.action { margin-top: .5em; }
	button { margin-top: 1em; }
	.events { margin-top: 5em; }
</style>