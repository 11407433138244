<script lang="ts">
	let active = false;

	export function anim() {
		active = true;
		setTimeout(()=>{
			active = false;
		}, 1500);
	}
</script>

{#if active}
	<img src="/billybot.gif" alt="">
{:else}
	<img src="/billybot.png" alt="">
{/if}



