
<script lang="ts">
    import Button from "../../components/Button.svelte";
	import { serviceCall } from "../../utils/services";
    import CommandEditor from "./CommandEditor.svelte";

	let commands = [];
	let currentCmd: any;
	let cmdName = null;
	const reloadCommands = async ()=>{
		commands = await serviceCall('twitchBot', 'getCommands');
	}
	reloadCommands().catch(e=>console.error('Error loading commands', e));

	const defaultCmd = {
		enabled: true,
		messageType: 'action',
		cooldown: 10000,
		aliases: []
	}

	const resetEditor = ()=>{
		currentCmd = JSON.parse(JSON.stringify(defaultCmd));
		cmdName=null;
	}
	resetEditor();

	const saveCommand = async (cmdName: string, cmd: unknown)=>{
		if(!cmdName) return;
		await serviceCall('twitchBot', 'editCommand', { name: cmdName, data: cmd});
		await reloadCommands();
	}

	const reloadEditor = () => {
		currentCmd = commands.find(c=>c.name===currentCmd.name);
		cmdName = currentCmd.name;
	}

</script>
<div>
	{#if commands.length }
	<h4>Command list</h4>
		<table>
			<tr>
				<th class="th-status">Status</th>
				<th class="th-name">Name</th>
				<th class="th-message">Message</th>
				<th class="th-type">Type</th>
				<th class="th-actions"></th>
			</tr>
			{#each commands as c }
				<tr>
					<td class="td-status"><input type="checkbox" bind:checked={c.enabled} on:change={()=>{saveCommand(c.name, c)}}/></td>
					<td>{c.name}</td>
					<td class="td-msg">{c.message}</td>
					<td>{c.messageType}</td>
					<td><button on:click={()=>{currentCmd=JSON.parse(JSON.stringify(c));cmdName=c.name;}}>✏️</button></td>
				</tr>
			{/each}
		</table>		
	{/if}
	<CommandEditor {cmdName} {currentCmd}>
		{#if cmdName}
			<Button cb={async()=>{await saveCommand(cmdName, currentCmd);resetEditor();}}>💾</Button>
		{:else}
			<Button cb={async()=>{await serviceCall('twitchBot', 'addCommand', {command : currentCmd});await reloadCommands();resetEditor();}} >💾</Button>
		{/if}
		<button on:click={()=>{resetEditor();}}>❌</button>
		{#if cmdName}
			<Button
				confirmation='Supprimer la commande ?'
				cb={async()=>{await serviceCall('twitchBot', 'removeCommand', {name: cmdName});await reloadCommands();resetEditor();}}
			>🗑️</Button>
		{/if}
	</CommandEditor>
</div>

<style>
	h4 { margin-top: 1em; }

	table { display: inline-table; margin: 1em; width: 45em; border-collapse: collapse; background-color: #222; table-layout: fixed; vertical-align: top; }
	tr:first-child { background-color: #333; }
	th { font-weight: bold; padding: .25em .5em; }
	.th-status { width: 3em; }
	.th-name { width: 6em;}
	.th-type { width: 8em;}
	.th-actions { width: 5em; }
	tr { border-bottom: 1px solid #aaa; }
	tr:last-child { border-bottom: none; }
	td { cursor: pointer; text-align: center; padding: .25em; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
	.td-status>* { width: 1em; height: 1em; }
	.td-msg { text-align: left; }
</style>