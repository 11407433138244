
<script lang="ts">
	import { useNavigate, useLocation } from "svelte-navigator";
    import { serviceCall } from "../../utils/services";

	const navigate = useNavigate();
	const location = useLocation()

	let auth = async ()=>{
		const code = new URLSearchParams(window.location.search).get("code");
		if(!code) {
			throw 'Not authorized';
		}else{
			await serviceCall('twitchUtils', 'connect', {code});
			navigate('/twitch',{
					state: { from: $location.pathname },
					replace: true,
				});		
		}
	};

</script>

{#await auth()}
	Saving autorization...
{:catch err} 
	Error  :/
	<br>
	{err.message ?? ''}
{/await}


