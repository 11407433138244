<script lang="ts">
    import { onDestroy, onMount } from "svelte";

	const lines = [];

	const first = [
		'VartacOS v2023.06',
		'',
		'vartac@vartacos:~$ billy system init',
	];

	const ascii = [
		'  ____ _____ _      _  __     ______   ____ _______ ',
		' |  _ \\_   _| |    | | \\ \\   / /  _ \\ / __ \\__   __|',
		' | |_) || | | |    | |  \\ \\_/ /| |_) | |  | | | |   ',
		' |  _ > | | | |    | |   \\   / |  _ >| |  | | | |   ',
		' | |_) || |_| |____| |____| |  | |_) | |__| | | |   ',
		' |____/_____|______|______|_|  |____/ \\____/  |_|   ',
		'By Salutations',
	];

	const fullSentences = [
		'Téléchargement de RAM additionnelle',
		'Installation de ArchLinux',
		'Connection à 127.0.0.1',
		'Connection au réseau Tor',
		'Réinitialisation du mot de passe',
		'Ajout de bugs supplémentaires',
		'Initialisation de la backdoor',
		'Mise à jour du ransomware',
        'Démarrage de l\'avatar',
        'Connection au supercalculateur',
        'Initialisation du retour vers le passé',
		'Connection à 2b2t',
		'Execution de rm -rf /'
	];

	const actions = [
		'Optimisation',
		'Téléchargement',
		'Compression',
		'Mise à jour',
		'Compilation',
		'Analyse',
		'Installation',
		'Suppression',
		'Activation',
		'Désactivation',
        'Mise en quarantaine',
        'Téléversement',
        'Transfert',
        'Dégustation',
	];

	const subject = [
		'de notepad.exe',
		'du dossier System32',
		'de dindons.zip',
		'de la base virale',
		'de malware.exe',
		'de ariux.jpg',
		'du module 49.3',
		'de aypierre.png',
		'de minecraft.jar',
        'de carapuce',
    	'des idées nulles',
    	'de la GoXLR',
		'du kernel Linux',
		'des idées nulles',
		'de la GoXLR',
		'du fond vert',
		'de la musique',
		'du melon',
		'des périphériques cérébraux',
		'de la caméra',
		'des p\'tits cubes',
		'de streamer.mp4',
		'des autographes',
		'de la raclette',
        'de sub.to.vartac.fr',
	];

	function pickRandom(arr: string[]) {
		return arr[Math.floor(Math.random() * arr.length)];
	}

	async function smoothWrite(sentence: string, delay: number = 50){
		lines.push('');
		for(let i=0;i<sentence.length;i++) {
			lines[lines.length-1]+=sentence.charAt(i);
			await new Promise(res=>setTimeout(res, delay));
		}
	}

	async function smoothDot(delay: number = 2000, randomDelay: number = 0){
		for(let i=0; i<3; i++) {
			await new Promise(res=>setTimeout(res, delay + Math.random()*randomDelay));
			lines[lines.length-1]+= '.';
		}
	}

	async function addSentence() {
		if(lines.length>=20) lines.shift();
		if(Math.random()>0.95) {
			const a = Math.ceil(Math.random()*10);
			const b = Math.ceil(Math.random()*10);
			let result = 0;
			if(Math.random()>0.5) {
				await smoothWrite('Calcul de '+a+'x'+b);
				result = a*b;
			}else{
				await smoothWrite('Calcul de '+a+'+'+b);
				result = a+b;
			}
			await smoothDot(100, 1000);
			await new Promise(res=>setTimeout(res, 1000 + Math.random()*1000));
			lines[lines.length-1]+= result;
		}else {
			if(Math.random()>0.9) {
				await smoothWrite(pickRandom(fullSentences), 100);
			}else{
				await smoothWrite(pickRandom(actions) + ' ' + pickRandom(subject), 100);
			}
			await smoothDot(100, 1000);
			await new Promise(res=>setTimeout(res, 1000 + Math.random()*1000));
			lines[lines.length-1]+='OK';
		} 
	}

	let exit = false;
	
	let x: NodeJS.Timer;
	let cursor = false;

	onMount(async ()=>{
		x = setInterval(()=>{cursor= !cursor}, 750);
		for(const line of first) {
			await smoothWrite(line, 5);
		}
		await new Promise(res=>setTimeout(res, 2000 ));
		for(const line of ascii) {
			await smoothWrite(line, 20);
		}
		await new Promise(res=>setTimeout(res, 2000 ));
		lines.push('');
		await smoothWrite('Initialisation', 100);
		await smoothDot(1000);
		await new Promise(res=>setTimeout(res, 1000));
		lines[lines.length-1]+='OK';
		await new Promise(res=>setTimeout(res, 5000 ));
		loop();
	});

	async function loop() {
		if(!exit) {
			await addSentence();
			await new Promise(res=>setTimeout(res, Math.random()*1000));
			return loop();
		}	
	}

	onDestroy(()=>{
		clearInterval(x);
		exit=true;
	});

</script>

<pre>{lines.join('\n') + (cursor ? '_':'') }</pre>

<style>
	pre { font-family: 'Courier New', Courier, monospace;  font-size: 1.75em;}
	pre { padding: .5em; height: 22em; }
	pre { background-color: black; color: #4AF626; }
</style>