
<script lang="ts">
	import { events } from "../../utils/liveEvents";
</script>

{#each $events as event }
	<div>
		{event.service}/{event.event}
		<pre>{ JSON.stringify(event.payload, null, 2)}</pre>
	</div>
{:else}
	No events for the moment
{/each}

<style>
	div { margin: 1em 0; }
	pre { display: inline-block; vertical-align: top; }
</style>