
<script lang="ts">
    import { io, type Socket } from "socket.io-client";
    import { onDestroy, onMount } from "svelte";
    import Billy from "./Views/Billy.svelte";
    import Rajah from "./Views/Rajah.svelte";
    import R2 from "./Views/R2.svelte";
    import Goal from "./Views/Goal.svelte";
    import BillyConsole from "./Views/BillyConsole.svelte";

	const views = {
		r2: R2,
		rajah: Rajah,
		billy: Billy,
		goal: Goal,
		billyconsole: BillyConsole
	}

	const params = new URLSearchParams(window.location.search);
	let socket: Socket;
	let view: any;
	let data = {};
	
	onMount(()=>{
		socket = io({
			auth: {
				view: params.get('view'),
				key: params.get('key')
			}
		});

		socket.once('viewType', (t)=>{
			console.log('Received view type '+t);
			view = views[t];
		});

		socket.on('update', (newData)=>{
			data = newData;
		});

		socket.on('exec', (func, args)=>{
			if(view && typeof view[func] === 'function') {
				try{
					view[func](args);
				}catch(e) {
					console.error('Error executing custom function');
				}
			}
		});

		socket.on('connect_error', error=>{
			console.error('socketio error', error);
		});
	});

	onDestroy(()=>{
		socket.close();
	});

</script>

<svelte:component this={view} {data} />
