
<script lang="ts">
	import { serviceCall } from "../../utils/services";
</script>

<h4>Linked accounts</h4>

{#await Promise.all([serviceCall('twitchUtils', 'getUsers'), serviceCall('twitchUtils', 'authUri')]) then [accounts, link] } 
	<ul>
		{#each accounts as account }
			<li>
				{ account.connected ? '✅' : '❌' } { account.userId } { account.label } <a href={link+"&scope="+account.scopes}>Connect</a>
			</li>
		{:else}
			<li>No accounts found</li>
		{/each}
	</ul>
{:catch e}
	{e.message ?? ''}
{/await}

<style>
	a { color: #4a87e8; text-decoration: underline; }
</style>