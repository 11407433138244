
<script lang="ts">
	let disabled = false;

	export let cb: ()=>Promise<void>;
	export let confirmation = '';

	const onClick = async ()=>{
		disabled = true;
		if(confirmation && !confirm(confirmation)) {
			disabled = false;
			return false;
		}
		try{
			await cb();
		}catch(e) {
			alert(e.message || 'erreur');
		}
		disabled = false;
	}
</script>

<button { disabled } on:click={onClick} >
	<slot/>
</button>