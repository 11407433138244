

export const api = async(method:'GET'|'POST'|'PUT'|'DELETE', endpoint:string, body?: any)=>{
	const req = {
		method: method,
		body: JSON.stringify(body),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
	}
	let resp: Response;
	//Try fetch
	try{
		resp = await fetch(endpoint, req);	
	}catch(e) {
		throw new Error('Network error');
	}
	//Check status
	if(resp.status<400) {
		try{
			//Return data if json
			return await resp.json();
		}catch(e) {
			return true;
		}		
	}

	//Server error: Get error message
	let message = 'Unknown error';
	try{
		const data = await resp.json();
		if(data.message) {
			message = data.message;
		}
	}catch(e) {}
	throw new Error(message);
}

export const serviceCall = async(service: string, action: string, body?: any)=>{
	return api('POST', '/api/services/'+service+'/'+action, body);
}
