
import { writable } from "svelte/store";
import { io } from "socket.io-client";


export const events = writable<any[]>([], ()=>{

	const socket = io({
		auth: {
			liveEvents: true
		}
	});
	
	socket.on('liveEvent', (event)=>{
		events.update((events)=>{ 
			events.unshift(event);
			if(events.length>50) events.pop();
			return events;
		} );
	});

	return ()=>socket.close();
});
