
<script lang="ts">
	import { Route } from "svelte-navigator";
	import RouteGuard from "./RouteGuard.svelte";

	export let path:string;

</script>

<Route {path} >
	<RouteGuard>
		<slot />
	</RouteGuard>
</Route>
