
<script lang="ts">
    import { Link } from "svelte-navigator";
	import Button from "../components/Button.svelte";
	import { serviceCall } from "../utils/services";
</script>

<h3>MiniRégie</h3>
<Link to="/">Back to panel</Link>
<div>
	{#each [0,1,2,3] as i}
	<div>
		<Button cb={ async()=>{ await serviceCall('obs', 'changeScene', {newScene: 's'+(i*2+1)}); } }>s{i*2+1}</Button>
		<Button cb={ async()=>{ await serviceCall('obs', 'changeScene', {newScene: 's'+(i*2+2)}); } }>s{i*2+2}</Button>
	</div>
	{/each}
</div>

<style>
	div :global(button) { font-size: 3em; margin: .25em; }
</style>