
<script lang="ts">
	import { user } from "../utils/stores";

</script>

<section>
	<h3>Vartux</h3>
	{#if $user.connected}
		Bienvenue { $user.login } sur ce panel incroyable !
	{:else if $user.url}
		<a href={$user.url}>Login</a>
	{/if}
</section>
