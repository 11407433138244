
import { writable as localWritable } from 'svelte-local-storage-store';
import { writable } from 'svelte/store';
import { api } from './services';

type SessionData = {
	connected?: boolean
	/** Twitch user id */
	userid?: string
	/** Twitch login */
	login?: string
	/** Auth url */
	url?: string
}

//Session info
export const user = writable<SessionData>({}, set => {
	api('GET', '/api/auth/status').then((res)=>{
		set(res);
	}).catch(e=>{
		console.error('Error getting auth status');
		set({connected: false});
	})
});

//Where to redirect after user is logged
export const authRedirect = localWritable<string>('authRedirect', '');
