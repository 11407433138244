<script lang="ts">
	let active = false;

	const audio = new Audio('/r2pop.mp3');

	export function anim(sound: boolean) {
		active = true;
		audio.play();
		setTimeout(()=>{
			active = false;
		}, 4000);
	}
</script>

{#if active}
	<img src="/r2pop.gif" alt="">
{:else}
	<img src="/r2.png" alt="">
{/if}
