
<script lang="ts">
	export let cmdName: string;
	export let currentCmd: any;

	let newAlias = '';
	const addAlias = ()=>{
		currentCmd.aliases.push(newAlias);
		currentCmd=currentCmd;
		newAlias='';
	}

	const delAlias = (i: number)=>{
		currentCmd.aliases.splice(i, 1);
		currentCmd=currentCmd;
	}

</script>

<div class="editor">
	<h5>{cmdName ? ('Editing command '+cmdName) : 'New command' }</h5>
	<label>
		<input type="checkbox" bind:checked={currentCmd.enabled}/> Enabled
	</label>
	<label>
		Name
		<input type="text" bind:value={currentCmd.name}/>
	</label>
	<label>
		Alias
		<div class="aliases">
			<input type="text" bind:value={newAlias} on:keyup={ (e)=> {if(e.key==='Enter') addAlias() }} />
			<button on:click={addAlias}>➕</button>
		</div>
		{#each currentCmd.aliases as alias, i}
			<div class="aliases">
				<input type="text" bind:value={alias} on:keyup={ (e)=> {if(e.key==='Delete') delAlias(i) }} />
				<button on:click={ ()=>{delAlias(i)} }>❌</button>
			</div>
		{/each }
	</label>
	<label>
		Message
		<textarea bind:value={currentCmd.message}></textarea>
	</label>
	<label>
		Message type
		<select bind:value={currentCmd.messageType}>
			<option value="reply">Reply</option>
			<option value="message">Message</option>
			<option value="action">Action (/me)</option>
			<option value="custom">Custom</option>
		</select>
	</label>
	<label>
		Cooldown (ms)
		<input type="number" step="1000" bind:value={currentCmd.cooldown}/>
	</label>
	<label>
		<input type="checkbox" bind:checked={currentCmd.random}/> Random broadcast
	</label>
	<slot><!--Buttons--></slot>
</div>


<style>
	.editor { display: inline-block; vertical-align: top; width: 20em; position: sticky; top: 2em; }
	.editor { font-size: 1.25em; margin: 1em; padding: 1em; border: 1px solid #aaa; }
	h5 { font-size: 1.25em; }
	label { width: 100%; }
	input[type=text],input[type=number],select,textarea { display: block; width: 100%; margin-top: .1em; }
	.aliases { display: flex; margin-bottom: .25em; }
	.aliases > button { margin-left: .5em; }
	select { font-size: 1em; padding: .1em; }
	textarea { height: 5em;}
</style>