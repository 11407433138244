<script lang="ts">
	export let data: any;
	const params = new URLSearchParams(window.location.search);

</script>

{#if params.get('display')==='bar'}
	<div id="out">
		<div id="in" style="width: { ((data.current/data.target) * 100) || 0}%; background-color: { params.get('color') || 'black'};" ></div>
	</div>
{:else}
	{#if data.target}
		<div id="text" style="color: {params.get('color') || 'black'}" >{data.current}/{data.target}</div>	
	{/if}
{/if}

<style type="text/css">
	@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
	#text {
		font-family: 'Bebas Neue', cursive;
		font-size: 8em;
	}

	#out {
		padding: 0;
		width: 48vw;
		height: 3.55vw;
		transform: skew(-10deg, 0);
		margin-left: 1vw;
	}

	#in {
		box-shadow: 0.6vw 0.25vw 0.3vw rgba(0, 0, 0, .4);
		height: 100%;
	}
</style>