<script lang="ts">
	import { useNavigate, useLocation } from "svelte-navigator";
	import { authRedirect, user } from "../utils/stores";

	const location = useLocation();

	$: if ($user.connected===false) {
		authRedirect.set($location.pathname);
		
		window.location.replace($user.url);
	}
</script>
{#if $user.connected === true }
	<slot/>
{/if}