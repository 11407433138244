
<script lang="ts">
    import Button from "../components/Button.svelte";
	import { serviceCall } from "../utils/services";

	let views = [];

	async function refreshViews() {
		views = await serviceCall('views', 'getViews');
	};

	async function regenKey(view: string) {
		await serviceCall('views', 'regenKey', {view});
		await refreshViews();
	};

	refreshViews().catch();

	function buildLink(view: {key: string, name: string}) {
		return window.location.origin+'/view?view='+view.name+'&key='+view.key;
	}

	const staticViews = [
		{
			name: 'Timer 10min',
			address: '/timer.html'
		},{
			name: 'Timer 10min (text)',
			address: '/timertext.html'
		},{
			name: 'Chatbox',
			address: '/chatbox.html'
		},{
			name: 'Chatbox Minecraft',
			address: '/chatmc.html'
		}
	]
</script>

<section>
	<h3>Dynamic views</h3>
	{#if views.length }
			{#each views as view }
				<div>
					<a target="_blank" rel="noopener noreferrer" href={buildLink(view)}><h4>{view.name}</h4></a>
					<Button cb={async()=>{await navigator.clipboard.writeText(buildLink(view))}}>Copy link</Button>
					<Button cb={async()=>{await regenKey(view.name)}}>Reset key</Button>
				</div>
			{/each}
	{:else}
		<p>No views</p>
	{/if}
	<h3>Static views</h3>
		{#each staticViews as view }
			<div>
				<a target="_blank" rel="noopener noreferrer" href={view.address}><h4>{view.name}</h4></a>
				<Button cb={async()=>{await navigator.clipboard.writeText(window.location.origin + view.address )}}>Copy link</Button>
			</div>			
		{/each}
</section>

<style>
	h4 { font-size: 1.25em; }
	div { margin-bottom: .5em; }
</style>