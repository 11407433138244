
<script lang="ts">
	import { useNavigate, useLocation } from "svelte-navigator";
    import { api } from "../utils/services";
	import { authRedirect, user } from "../utils/stores";

	const navigate = useNavigate();
	const location = useLocation()

	let msg = 'Redirecting to panel';
	(async ()=>{
		try {
			const code = new URLSearchParams(window.location.search).get("code");
			if(!code) {
				msg = 'Error: No authorization';
			}else{
				const resp = await api('POST', '/api/auth/verify', {code});
				if(resp.connected) {
					user.set({
						connected: true,
						login: resp.login
					});
					navigate($authRedirect || '/',{
						state: { from: $location.pathname },
						replace: true,
					});
					authRedirect.set('');
				}else{
					msg = 'Error: Couldn\'t verify with twitch';
				}			
			}
		}catch(e) {
			msg = 'Error';
			console.error(e);
		}
	})();

</script>

<section>
	<h3>Redirecting</h3>
	<p>{msg}</p>	
</section>

<style>
</style>