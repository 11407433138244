<script lang="ts">
	let active = false;

	const audio = new Audio('/rajahboum.mp3');

	export function anim(sound: boolean) {
		active = true;
		audio.play();
		setTimeout(()=>{
			active = false;
		}, 5000);
	}
</script>

{#if active}
	<img src="/rajahboum.gif" alt="">
{:else}
	<img src="/rajah.png" alt="">
{/if}
